import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {IGarageService} from '../_interfaces/garage-service.interface';
import {GarageServiceService} from '../_services/garage-service.service';

@Injectable()
export class GarageServiceResolve implements Resolve<IGarageService[]> {
  constructor(private garageServiceService: GarageServiceService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IGarageService[]> {
    return this.garageServiceService.getAll();
  }
}
