import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GaragesService } from '../_services/garages.service';
import {IEmployee} from '../_interfaces/employee.interface';
import {EmployeeService} from '../_services/employee.service';

@Injectable()
export class EmployeesResolve implements Resolve<IEmployee[]> {
  constructor(private employeesService: EmployeeService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IEmployee[]> {
    return this.employeesService.getAll();
  }
}
