import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DirectoriesService } from '../_services/directories.service';
import {IDirectory} from '../_interfaces/directory.interface';

@Injectable()
export class DirectoriesResolve implements Resolve<IDirectory[]> {
  constructor(private directoriesService: DirectoriesService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IDirectory[]> {

    const id: any = route.params.garage_id;

    if (id === 0) {
      // return this.directoriesService.getAll(id);
    }

    return this.directoriesService.getAll();
  }
}
