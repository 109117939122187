import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { HttpClientModule } from '@angular/common/http';

import {TopbarComponent} from './_components/topbar.component';
import {ClockbarComponent} from './_components/clockbar.component';
import { FooterComponent } from './_components/footer.component';
import {HomeComponent} from './home/home.component';
import { UserComponent } from './user/user.component';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import { GarageComponent } from './garage/garage.component';
import { BreadcrumbComponent } from './_components/breadcrumb.component';
import { PlanComponent } from './garage/plan/plan.component';
import { TeamComponent } from './garage/team/team.component';
import {DirectoriesResolve} from './_resolvers/directories.resolve';
import {GaragesResolve} from './_resolvers/garages.resolve';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {ServicePlanDayResolve} from './_resolvers/service-plan-day.resolve';
import {EmployeesResolve} from './_resolvers/employees.resolve';
import {GarageServiceResolve} from './_resolvers/garage-service.resolve';
import {GarageServicePlanResolve} from './_resolvers/garage-service-plan.resolve';

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    ClockbarComponent,
    FooterComponent,
    HomeComponent,
    UserComponent,
    GarageComponent,
    BreadcrumbComponent,
    PlanComponent,
    TeamComponent,
  ],
  imports: [
    LoadingBarHttpClientModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    DirectoriesResolve,
    GaragesResolve,
    EmployeesResolve,
    GarageServiceResolve,
    GarageServicePlanResolve,
    ServicePlanDayResolve,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
