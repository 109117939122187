import { NgModule } from '@angular/core';
import {Routes, RouterModule, Router, NavigationEnd} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {HomeComponent} from './home/home.component';
import {GarageComponent} from './garage/garage.component';
import {PlanComponent} from './garage/plan/plan.component';
import {TeamComponent} from './garage/team/team.component';
import {DirectoriesResolve} from './_resolvers/directories.resolve';
import {GaragesResolve} from './_resolvers/garages.resolve';
import {IGarage} from './_interfaces/garage.interface';
import {IServicePlanDay} from './_interfaces/service-plan-day.interface';
import {ServicePlanDayResolve} from './_resolvers/service-plan-day.resolve';
import {GarageServicePlanResolve} from './_resolvers/garage-service-plan.resolve';
import {IGarageServicePlan} from './_interfaces/garage-service-plan.interface';
import {IGarageService} from './_interfaces/garage-service.interface';
import {GarageServiceResolve} from './_resolvers/garage-service.resolve';
import {IEmployee} from './_interfaces/employee.interface';
import {EmployeesResolve} from './_resolvers/employees.resolve';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: {
      directories: DirectoriesResolve,
      garages: GaragesResolve,
    },
    data: {title: 'Home'}
  },
  {
    path: 'garage/:garage_id',
    component: GarageComponent,
    resolve: {
      garage: GaragesResolve,
      servicePlanDays: ServicePlanDayResolve,
      servicePlans: GarageServicePlanResolve,
    },
    data: {title: 'Garage'},
  },
  {
    path: 'garage/:garage_id/plan/:servicePlanDay_id',
    component: PlanComponent,
    resolve: {
      servicePlanDay: ServicePlanDayResolve,
      garage: GaragesResolve,
      servicePlans: GarageServicePlanResolve,
      services: GarageServiceResolve,
    },
    data: {title: 'Plan'},
  },
  {
    path: 'garage/:garage_id/team',
    component: TeamComponent,
    resolve: {
      garage: GaragesResolve,
      employees: EmployeesResolve,
    },
    data: {title: 'Team'},
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(titleService: Title, router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(router.routerState, router.routerState.root).join('::');
        titleService.setTitle(title);
      }
    });
  }

  // collect that title data properties from all child routes
  // there might be a better way but this worked for me
  getTitle(state, parent): any[] {

    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title + ' :: Ramis');
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

}
