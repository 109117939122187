<nav class="navbar navbar-expand-lg navbar-light scrolling-navbar double-nav shadow-none px-3 py-1 rgba-grey-slight">
  <div class="d-flex justify-content-between align-items-center w-100">
    <a href="/usermenu/" class="btn btn-sm btn-rounded px-2 shadow-none rgba-grey-slight">
      <i class="zmdi zmdi-account zmdi-hc-2x px-1"></i>
    </a>

    <div>
      <img src="../../assets/img/svg/logo-symbol.svg" class="img-fluid" style="height: 40px;"/>
    </div>

    <div class="d-flex align-items-center flex-column">

      <span id="topbar-notification-counter-badge" class="ramis-bg-red position-absolute shadow-none badge" style="z-index: 1000; margin-top: 5px; margin-left: 15px; display: none;">0</span>

      <a href="/meldungen/" class="btn btn-sm btn-rounded px-2 shadow-none rgba-grey-slight">
        <i class="zmdi zmdi-notifications zmdi-hc-2x px-1"></i>
      </a>

    </div>


  </div>
</nav>
