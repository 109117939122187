import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clockbar',
  templateUrl: './clockbar.component.html',
  styleUrls: ['./clockbar.component.scss']
})
export class ClockbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.setClockBar();
  }

  setClockBar(): void {
    const dateField = document.getElementById('clock-bar-date');
    const date = new Date();
    const weekday = this.getGermanWeekday(date.getUTCDay());
    const day = (date.getDate() <= 9) ? '0' + date.getDate() : date.getDate();
    const dateMonth = date.getMonth() + 1;
    const month = (dateMonth <= 9) ? '0' + dateMonth : dateMonth;
    const year = date.getFullYear();

    dateField.innerHTML = weekday + ', ' + day + '.' + month + '.' + year;

    this.clock();
  }


  clock(): void {
    const digital = new Date();
    const hours = digital.getHours();
    const minutes = digital.getMinutes();
    const seconds = digital.getSeconds();
    let strHours = hours.toString();
    let strMinutes = minutes.toString();
    let strSeconds = seconds.toString();

    if (hours <= 9) {
      strHours = '0' + hours;
    }

    if (minutes <= 9) {
      strMinutes = '0' + minutes;
    }

    if (seconds <= 9) {
      strSeconds = '0' + seconds;
    }

    document.getElementById('clock-bar-clock').innerHTML = strHours + ':' + strMinutes + ':' + strSeconds;

    setTimeout(() => {
      this.clock();
    }, 1000);
  }

  getGermanWeekday(weekday): string {
    switch (weekday) {
      case 1:
        return 'Mo';
      case 2:
        return 'Di';
      case 3:
        return 'Mi';
      case 4:
        return 'Do';
      case 5:
        return 'Fr';
      case 6:
        return 'Sa';
      case 0:
        return 'So';
      default:
        return null;
    }
  }

}
