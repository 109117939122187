import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IEmployee} from '../_interfaces/employee.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  API_TARGET_URL = '/employee';

  constructor(
    private http: HttpClient
  ) {}

  public getAll(): Observable<IEmployee[]>{
    return this.http.get<IEmployee[]>(`${environment.apiUrl}` + this.API_TARGET_URL).pipe();;
  }

  public getBy(params: object): Observable<IEmployee[]> {

    let httpParams: HttpParams = new HttpParams();

    for (const [key, value] of Object.entries(params)) {
      httpParams = httpParams.set(key, value);
    }

    return this.http.get<IEmployee[]>(`${environment.apiUrl}` + this.API_TARGET_URL, {
        params: httpParams,
      }).pipe();
  }
}
