
<app-breadcrumb pageTitle="{{ garage.name }}" backLink="{{ backlink }}" subtitle="PU-Garagen"></app-breadcrumb>

<main class="mt-5">
  <div class="container-fluid">
    <section class="pt-4">

      <h6 class="small pac-color-dark text-uppercase font-weight-bold mb-4">Dienstbeschreibung</h6>

      <div class="row pac-color-dark mx-0 d-flex justify-content-center">

        <div *ngFor="let element of servicePlanDays" class="col-3 mb-2 px-1">
          <a [routerLink]="['/garage', garage.id, 'plan', element.name]" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2 "
             [ngStyle]="{'background-color': element.color }" >
            <i class="zmdi zmdi-calendar-note mb-1 px-1 pt-2 font-24"></i>
            <span class="text-uppercase font-10">{{ element.name }}</span>
          </a>
        </div>

      </div>
    </section>

    <section class="pt-3">

      <h6 class="small pac-color-dark text-uppercase font-weight-bold mb-4">Allgemein</h6>

      <div class="row pac-color-dark mx-0 d-flex justify-content-center">

        <div class="col-3 mb-2 px-1">
          <a [routerLink]="['/garage', garage.id, 'team']" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
            <i class="zmdi zmdi-accounts mb-1 px-1 pt-2 font-24"></i>
            <span class="text-uppercase font-10">Team</span>
          </a>
        </div>

        <div class="col-3 mb-2 px-1">
          <a href="#" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
            <i class="zmdi zmdi-directions-bus mb-1 px-1 pt-2 font-24"></i>
            <span class="text-uppercase font-10">Fahrzeuge</span>
          </a>
        </div>

      </div>
    </section>

    <section class="pt-3">

      <h6 class="small pac-color-dark text-uppercase font-weight-bold mb-4">Verzeichnisse</h6>

      <div class="row pac-color-dark mx-0 d-flex justify-content-center">

        <div *ngFor="let element of garage.directories" class="col-3 mb-2 px-1">
          <a href="#" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
            <i class="{{ element.iconName }} mb-1 px-1 pt-2 font-24"></i>
            <span class="text-uppercase font-10">{{ element.name }}</span>
          </a>
        </div>

      </div>
    </section>
  </div>
</main>
