<app-breadcrumb pageTitle="Team" backLink="{{ backlink }}" subtitle="{{ garage.name }}"></app-breadcrumb>

<main class="mt-5">
  <div class="container-fluid">

    <section class="pt-4">

      <div *ngFor="let element of employees" class="d-flex justify-content-between align-items-center border-top">


        <a *ngIf="!element.photo; else withPhoto" href="javascript:" class="flex-shrink-1 pr-3 d-flex justify-content-center">
          <img src="assets/img/no-image.png" class="img-fluid" alt="{{ element.surname }}, {{ element.name }}" style="height: 50px; max-height: 50px;">
        </a>

        <ng-template #withPhoto>
          <a href="{{ API_DOCUMENTS_URL }}{{ element.photo.file }}" class="flex-shrink-1 pr-3 d-flex justify-content-center" data-lightbox="{{ element.surname }}, {{ element.name }}">
            <img src="{{ API_DOCUMENTS_URL }}{{ element.photo.file }}" class="img-fluid" alt="{{ element.surname }}, {{ element.name }}" style="height: 50px; max-height: 50px;">
          </a>
        </ng-template>


        <a href="tel:{{ element.phoneNumber }}" class="btn btn-flat m-0 btn btn-flat m-0 pl-0 flex-grow-1 pr-3 text-left font-weight-normal" style="text-transform: unset">
          {{ element.surname }}, {{ element.name }}
        </a>
        <a href="tel:{{ element.phoneNumber }}" class=" btn btn-flat m-0 flex-shrink-1 btn btn-flat m-0 py-4">
          <i class="zmdi zmdi-phone-in-talk font-18"></i>
        </a>

      </div>

    </section>

  </div>
</main>
