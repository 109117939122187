import { Injectable } from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { GaragesService } from '../_services/garages.service';
import {IGarage} from '../_interfaces/garage.interface';

@Injectable()
export class GaragesResolve implements Resolve<IGarage[]|IGarage> {
  constructor(private garagesService: GaragesService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IGarage[]|IGarage> {
    const id: any = route.params.garage_id;

    if (id !== undefined) {
      return this.garagesService.get(id);
    }

    return this.garagesService.getAll();

  }
}
