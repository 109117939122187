<section class="position-fixed pac-bg-grey-slight px-3 py-2" style="left: 0; right: 0; bottom: 0;">
  <div class="d-flex justify-content-between align-items-center ramis-color-dark w-100">
    <a href="#" class="px-2 py-1 d-flex flex-column justify-content-center align-items-center">
      <i class="zmdi zmdi-comment-text m-0 p-0 font-25"></i>
    </a>

    <a href="#" class="d-flex flex-column justify-content-center align-items-center">
      <span class="d-block font-10">RAMIS 1.0</span>
      <span class="d-block font-10 font-italic">by intek.ch</span>
    </a>

    <a href="#" class="px-2 py-1 d-flex flex-column justify-content-center align-items-center">
      <i class="zmdi zmdi-share m-0 p-0 font-25"></i>
    </a>
  </div>

</section>
