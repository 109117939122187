import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {IServicePlanDay} from '../_interfaces/service-plan-day.interface';
import {ServicePlanDayService} from '../_services/service-plan-day.service';

@Injectable()
export class ServicePlanDayResolve implements Resolve<IServicePlanDay[]> {
  constructor(private servicePlanDayService: ServicePlanDayService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IServicePlanDay[]> {
    return this.servicePlanDayService.getAll();
  }
}
