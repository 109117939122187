import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {IGarageServicePlan} from '../_interfaces/garage-service-plan.interface';
import {GarageServicePlanService} from '../_services/garage-service-plan.service';

@Injectable()
export class GarageServicePlanResolve implements Resolve<IGarageServicePlan[]> {
  constructor(private garageServicePlanService: GarageServicePlanService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IGarageServicePlan[]> {

    const id: any = route.params.garage_id;

    if (id === 0) {
      return this.garageServicePlanService.getBy({garage: id});
    }

    return this.garageServicePlanService.getAll();
  }
}
