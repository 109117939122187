import { Component, OnInit } from '@angular/core';
import {GaragesService} from '../../_services/garages.service';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {EmployeeService} from '../../_services/employee.service';
import {IGarage} from '../../_interfaces/garage.interface';
import {IEmployee} from '../../_interfaces/employee.interface';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  API_DOCUMENTS_URL = 'https://api.ramis.tonova.ch/uploads/employees/photos/';

  private sub: any;

  backlink = null;
  garageId: number;

  garage: IGarage;
  employees: IEmployee[] = [];

  constructor(
    private garagesService: GaragesService,
    private employeesService: EmployeeService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => {
      this.garageId = +params[`garage_id`]; // (+) converts string 'id' to a number
      this.backlink = this.router.createUrlTree(['garage', this.garageId]);
    });

    this.garage = this.route.snapshot.data.garage;
    this.employees = this.route.snapshot.data.employees._embedded.employee;

  }

}
