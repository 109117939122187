<section class="pac-bg-grey-slight border border-bottom" style="position: fixed; top: 5.7rem; left: 0; right: 0; width: 100%; z-index: 1000;">

  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex justify-content-start align-items-center">
      <a id="breadcrumb-back-link" [routerLink]="backLink"
         class="btn btn-flat btn-sm m-0 py-3 px-4 border-light border-right rounded-0">
        <i class="zmdi zmdi-chevron-left zmdi-hc-2x"></i>
      </a>

      <div class="d-flex flex-column justify-content-center align-items-start pl-3">
        <h6 class="m-0 font-weight-bold font-16">{{ pageTitle }}</h6>

        <span *ngIf="subtitle != null" class="mt-0 d-block text-muted font-12">{{ subtitle }}</span>

      </div>

    </div>

    <div>
      <a routerLink="" class="btn btn-flat btn-sm m-0 py-3 px-4 border-light border-left rounded-0">
        <i class="zmdi zmdi-home zmdi-hc-2x"></i>
      </a>
    </div>

  </div>

</section>
