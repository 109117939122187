<main style="overflow-y: auto;">
  <div class="container-fluid">

  <section class="pt-4">

    <h6 class="small ramis-color-dark text-uppercase font-weight-bold mb-4">PU-GARAGEN</h6>

    <div class="row ramis-color-dark mx-0 d-flex justify-content-center">

      <div *ngFor="let garage of garages" class="col-3 mb-2 px-1">
        <a [routerLink]="['/garage', garage.id]" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
          <i class="fas fa-warehouse mb-2 px-1 pt-2 font-18"></i>
          <span class="text-uppercase font-10">{{ garage.name }}</span>
        </a>
      </div>

    </div>

  </section>

  <section class="pt-4">

    <h6 class="small ramis-color-dark text-uppercase font-weight-bold mb-4">ALLGEMEIN</h6>

    <div class="row ramis-color-dark mx-0 d-flex justify-content-center">

      <div class="col-3 mb-2 px-1">
        <a href="#" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
          <i class="zmdi zmdi-accounts mb-1 px-1 pt-2 font-24"></i>
          <span class="text-uppercase font-10">Mitarbeiter</span>
        </a>
      </div>

      <div class="col-3 mb-2 px-1">
        <a href="https://67871b6b-7f93-4f52-b5c1-4edb5a745d0b-miku.app.sbb.ch/#/home"
           target="_blank"
           class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
          <i class="zmdi zmdi-directions-railway mb-1 px-1 pt-2 font-24"></i>
          <span class="text-uppercase font-10">MIKU</span>
        </a>
      </div>

      <div class="col-3 mb-2 px-1">
        <a href="#" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
          <i class="zmdi zmdi-account-box-phone mb-1 px-1 pt-2 font-24"></i>
          <span class="text-uppercase font-10">Kontakte</span>
        </a>
      </div>

      <div class="col-3 mb-2 px-1">
        <a href="#" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
          <i class="zmdi zmdi-calendar mb-1 px-1 pt-2 font-24"></i>
          <span class="text-uppercase font-10">Monatsplan</span>
        </a>
      </div>

      <div class="col-3 mb-2 px-1">
        <a href="#" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
          <img src="assets/img/svg/dakos-logo.svg" class="img-fluid text-dark mb-1 pt-2" alt="Dakos Logo" style="max-height: 32px">
          <span class="text-uppercase font-10">DAKOS</span>
        </a>
      </div>

      <div class="col-3 mb-2 px-1">
        <a href="https://kanbanflow.com/login" target="_blank" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
          <i class="zmdi zmdi-view-column mb-1 px-1 pt-2 font-24"></i>
          <span class="text-uppercase font-10">KanBan</span>
        </a>
      </div>

    </div>

  </section>

  <section class="pt-3">

    <h6 class="small pac-color-dark text-uppercase font-weight-bold mb-4">Verzeichnisse</h6>

    <div class="row pac-color-dark mx-0 d-flex justify-content-center">

      <div *ngFor="let directory of directories" class="col-3 mb-2 px-1">
        <a href="#" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-2">
          <i class="{{ directory.iconName }} mb-2 px-1 pt-2 font-18"></i>
          <span class="text-uppercase font-10">{{ directory.name }}</span>
        </a>
      </div>

    </div>
  </section>
  </div>
</main>
