import { Component, OnInit } from '@angular/core';
import {DirectoriesService} from '../_services/directories.service';
import {GaragesService} from '../_services/garages.service';
import {IDirectory} from '../_interfaces/directory.interface';
import {IGarage} from '../_interfaces/garage.interface';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  directories: IDirectory[] = [];
  garages: IGarage[] = [];

  constructor(
    private directoriesService: DirectoriesService,
    private garagesService: GaragesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.directories = this.route.snapshot.data.directories._embedded.category;
    this.garages = this.route.snapshot.data.garages._embedded.garage;
  }
}
