import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {IDirectory} from '../_interfaces/directory.interface';


@Injectable({
  providedIn: 'root'
})
export class DirectoriesService {

  API_TARGET_URL = '/directory/category';

  constructor(
    private http: HttpClient
  ) {}

  public getAll(): Observable<IDirectory[]>{
    return this.http.get<IDirectory[]>(`${environment.apiUrl}` + this.API_TARGET_URL).pipe();
  }
}
