import { Component, OnInit } from '@angular/core';
import {ServicePlanDayService} from '../../_services/service-plan-day.service';
import {first} from 'rxjs/operators';
import {GaragesService} from '../../_services/garages.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GarageServicePlanService} from '../../_services/garage-service-plan.service';
import {GarageServiceService} from '../../_services/garage-service.service';
import {IGarage} from '../../_interfaces/garage.interface';
import {IServicePlanDay} from '../../_interfaces/service-plan-day.interface';
import {IGarageServicePlan} from '../../_interfaces/garage-service-plan.interface';
import {IGarageService} from '../../_interfaces/garage-service.interface';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  API_DOCUMENTS_URL = 'https://api.ramis.tonova.ch/uploads/garages/service-plans/';

  private sub: any;

  backlink = null;
  garageId: number;
  servicePlanDayId: number;

  servicePlanDay: IServicePlanDay;
  garage: IGarage;
  servicePlans: IGarageServicePlan[] = [];
  services: IGarageService[] = [];

  constructor(
    private garagesService: GaragesService,
    private servicePlanDayService: ServicePlanDayService,
    private servicePlanService: GarageServicePlanService,
    private serviceService: GarageServiceService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => {
      this.garageId = +params[`garage_id`]; // (+) converts string 'id' to a number
      this.backlink = this.router.createUrlTree(['garage', this.garageId]);
      this.servicePlanDayId = +params[`servicePlanDay_id`]; // (+) converts string 'id' to a number
    });

    this.garage = this.route.snapshot.data.garage;
    this.servicePlanDay = this.route.snapshot.data.servicePlanDay;
    this.servicePlans = this.route.snapshot.data.servicePlans._embedded.garage_service_plan;
    this.services = this.route.snapshot.data.services._embedded.garage_service;

  }
}
