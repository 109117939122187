import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IGarage} from '../_interfaces/garage.interface';

@Injectable({
  providedIn: 'root'
})
export class GaragesService {

  API_TARGET_URL = '/garage';

  constructor(
    private http: HttpClient
  ) {}

  public getAll(): Observable<IGarage[]>{
    return this.http.get<IGarage[]>(`${environment.apiUrl}` + this.API_TARGET_URL).pipe();
  }

  public get(id: number): Observable<IGarage>{
    return this.http.get<IGarage>(`${environment.apiUrl}` + this.API_TARGET_URL + `/${id}`).pipe();
  }
}
