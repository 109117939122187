import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IServicePlanDay} from '../_interfaces/service-plan-day.interface';

@Injectable({
  providedIn: 'root'
})
export class ServicePlanDayService {

  API_TARGET_URL = '/service-plan-day';

  constructor(
    private http: HttpClient
  ) {}

  public getAll(): Observable<IServicePlanDay[]>{
    return this.http.get<IServicePlanDay[]>(`${environment.apiUrl}` + this.API_TARGET_URL).pipe();
  }

  public get(id: number): Observable<IServicePlanDay>{
    return this.http.get<IServicePlanDay>(`${environment.apiUrl}` + this.API_TARGET_URL + `/${id}`).pipe();
  }
}
