import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IGarageServicePlan} from '../_interfaces/garage-service-plan.interface';

@Injectable({
  providedIn: 'root'
})
export class GarageServicePlanService {

  API_TARGET_URL = '/garage/service-plan';

  constructor(
    private http: HttpClient
  ) {}

  public getAll(): Observable<IGarageServicePlan[]>{
    return this.http.get<IGarageServicePlan[]>(`${environment.apiUrl}` + this.API_TARGET_URL).pipe();
  }

  public getBy(params: object): Observable<IGarageServicePlan[]> {

    const httpParams: HttpParams = new HttpParams();

    for (const [key, value] of Object.entries(params)) {
      httpParams.set(key, value);
    }

    return this.http.get<IGarageServicePlan[]>(
      `${environment.apiUrl}` + this.API_TARGET_URL, {
        params: httpParams,
      }).pipe();
  }

  public get(id: number): Observable<IGarageServicePlan>{
    return this.http.get<IGarageServicePlan>(`${environment.apiUrl}` + this.API_TARGET_URL + `/${id}`).pipe();
  }
}
