import { Component, OnInit } from '@angular/core';
import {GaragesService} from '../_services/garages.service';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ServicePlanDayService} from '../_services/service-plan-day.service';
import {GarageServicePlanService} from '../_services/garage-service-plan.service';
import {IGarage} from '../_interfaces/garage.interface';
import {IServicePlanDay} from '../_interfaces/service-plan-day.interface';
import {IGarageServicePlan} from '../_interfaces/garage-service-plan.interface';

@Component({
  selector: 'app-garages',
  templateUrl: './garage.component.html',
  styleUrls: ['./garage.component.scss']
})
export class GarageComponent implements OnInit {

  id: number;
  backlink = null;
  private sub: any;
  garage: IGarage;
  servicePlanDays: IServicePlanDay[] = [];
  servicePlans: IGarageServicePlan[] = [];

  constructor(
    private garagesService: GaragesService,
    private servicePlanDayService: ServicePlanDayService,
    private servicePlanService: GarageServicePlanService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => {
      this.id = +params[`garage_id`]; // (+) converts string 'id' to a number
      this.backlink = this.router.createUrlTree(['']);
    });

    this.servicePlanDays = this.route.snapshot.data.servicePlanDays._embedded.service_plan_day;
    this.garage = this.route.snapshot.data.garage;
    this.servicePlans = this.route.snapshot.data.servicePlans._embedded.service_plan;

  }

}
