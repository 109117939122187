<app-breadcrumb pageTitle="Dienste {{ servicePlanDay.name }}" backLink="{{ backlink }}" subtitle="{{ garage.name}}"></app-breadcrumb>

<main class="mt-5">
  <div class="container-fluid">

    <section class="pt-4">

      <h6 class="small pac-color-dark text-uppercase font-weight-bold mb-4">Zusammenfassung</h6>

      <div class="row pac-color-dark mx-0 d-flex justify-content-start">


        <ng-container *ngFor="let parent of servicePlans" >

            <div *ngFor="let element of parent['documents']" class="col-6 mb-3">

              <a href="{{ API_DOCUMENTS_URL }}{{ element['file'] }}"
                 class="btn btn-flat m-0 d-flex align-items-center justify-content-start pl-2 flex-grow-1"
                 data-lightbox="Zusammenfassung">

                <img class="img-fluid" src="{{ API_DOCUMENTS_URL }}{{ element['file'] }}" alt="Zusammenfassung Dokument">
              </a>
            </div>

        </ng-container>


      </div>
    </section>

    <section class="pt-3">

      <h6 class="small pac-color-dark text-uppercase font-weight-bold mb-4">Dienstliste</h6>

      <div class="row pac-color-dark mx-0 d-flex justify-content-center">



        <div *ngFor="let element of services" class="col-3 mb-2 px-1">
          <a href="#" class="card btn btn-flat m-0 border text-center d-flex flex-column px-1 py-3">
            <span class="text-nowrap">{{ element['name'] }}</span>
          </a>
        </div>



      </div>
    </section>

  </div>
</main>
